import PropTypes from "prop-types";
import LoadingButton from "@mui/lab/LoadingButton";

export const UIButton = ({
  width = "100%",
  type = "button",
  label,
  children,
  sx,
  ...props
}) => {
  let addSX = sx ? sx : { width: width };

  return (
    <LoadingButton
      variant="contained"
      sx={addSX}
      type={type}
      {...props}
    >
      {label}
      {children}
    </LoadingButton>
  );
};

UIButton.propTypes = {
  width: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
};
