import img from "../../assets/404.png";
import { Box, Typography, Divider } from "@mui/material";

export const NotFound = () => {
  return (
    <>
      <Box
        sx={{
          width: "fit-content",
          margin: "0px auto",
          "@media (max-width: 600px)": {
            width: "90%",
          },
        }}
      >
        <Box
          sx={{ display: "grid", placeItems: "center", textAlign: "center" }}
        >
          <img
            src={img}
            alt="Not Found"
            style={{
              width: "90%",
            }}
          />
          <Typography variant="h3" gutterBottom>
            Page not found
          </Typography>
          <Typography variant="h6" gutterBottom>
            The page you are looking for doesn't exist or an other error
            occurred.
          </Typography>

          <Divider sx={{ width: "100%", margin: "20px 0px" }} />

          <Typography variant="h3" gutterBottom>
            Página no encontrada
          </Typography>
          <Typography variant="h6" gutterBottom>
            La página que estás buscando no existe o se produjo otro error.
          </Typography>
        </Box>
      </Box>
    </>
  );
};
