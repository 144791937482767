import { useEffect } from "react";
import { useSelector } from "react-redux";
import { publicSelect } from "@redux/slices/public";
import { Box } from "@mui/material";

export const Policy = () => {
  const { information } = useSelector(publicSelect);

  const InjectedHTML = ({ html, targetId }) => {
    const injectHTML = () => {
      const targetDiv = document.getElementById(targetId);
      if (targetDiv) {
        targetDiv.innerHTML = html;
      }
    };

    useEffect(() => {
      injectHTML();
    }, []);

    return null;
  };
  return (
    <>
      <Box id="target-div" sx={{ padding: { xs: "1rem", sm: "2rem 9rem" } }} />
      <InjectedHTML html={information?.privacyPolicy} targetId="target-div" />
    </>
  );
};
