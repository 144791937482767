import { TextField } from "@mui/material";
import PropTypes from "prop-types";

export const UITextField = ({
  formik,
  label = "",
  name = "",
  type = "text",
  placeholder = "",
  nameFileArray = "",
  index = null,
  isrequired = true,
  disabled = false,
  width = "100%",
  style,
  InputProps,
  ...props
}) => {
  return (
    <TextField
      type={type}
      variant="outlined"
      label={label}
      placeholder={placeholder}
      name={name}
      value={
        nameFileArray === ""
          ? formik.values[name]
          : formik.values[nameFileArray][index][name]
      }
      onChange={
        nameFileArray === ""
          ? formik.handleChange
          : (event) => {
              formik.setFieldValue(
                `${nameFileArray}.${index}.${name}`,
                event.target.value
              );
            }
      }
      required={isrequired}
      disabled={disabled}
      sx={{
        width: width,
      }}
      style={style}
      InputProps={InputProps}
      onBlur={formik.handleBlur}
      error={formik.touched[name] && Boolean(formik.errors[name])}
      helperText={formik.touched[name] && formik.errors[name]}
      {...props}
    />
  );
};

UITextField.propTypes = {
  formik: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  nameFileArray: PropTypes.string,
  index: PropTypes.number,
  isrequired: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  width: PropTypes.string,
  style: PropTypes.object,
  InputProps: PropTypes.object,
};
