import { useState } from "react";
import { TemplateAuth } from "./TemplateAuth";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { Stepper, Step, StepLabel, Button, Box, Grid } from "@mui/material";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import { UIButton, UIPasswordField, UITextField } from "../components";
import { useDispatch, useSelector } from "react-redux";
import {
  publicSelect,
  setEmail,
  setPIN,
  setStep,
} from "../redux/slices/public";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import LockResetIcon from "@mui/icons-material/LockReset";

import styles from "./index.module.css";
import * as Yup from "yup";
import { postRecovery, postRecoveryEmail } from "../api/auth";
import { useNavigate } from "react-router-dom";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <AlternateEmailIcon />,
    2: <VpnKeyIcon />,
    3: <LockResetIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

export const RecoverPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation(["auth"]);

  const { step, formPassword } = useSelector(publicSelect);

  const [spinner, setSpinner] = useState(false);
  const [transition, setTransition] = useState(false);
  const [activeStep, setActiveStep] = useState(step);
  const [error, setError] = useState(false);
  const [msgError, setMsgError] = useState(false);

  const steps = [
    t("auth:Confirmeelcorreoelectrónico"),
    t("auth:IngreseelPINtemporal"),
    t("auth:Creesunuevacontraseña"),
  ];

  const validations = Yup.object().shape({
    workEmail: Yup.string()
      .required(t("auth:workEmailRequired"))
      .email(t("auth:workEmailEmail")),
    workEmailConfirm: Yup.string()
      .oneOf([Yup.ref("workEmail"), null], t("auth:workEmailConfirmOneOf"))
      .required(t("auth:workEmailConfirmRequired")),
  });

  const passwordRegex =
    /^(?=(?:.*[A-Z]){1})(?=(?:.*[a-z]){1})(?=(?:.*[0-9]){1})(?!.*[<]|.*&#)(?=.*[!$@=?¿¡%*+-_])[A-Za-z0-9!$@=?¿¡%*+-_]{10,}$/;

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .matches(passwordRegex, t("auth:passwordMatches"))
      .required(t("auth:passwordRequired")),
    password2: Yup.string()
      .oneOf([Yup.ref("password"), null], t("auth:password2oneOf"))
      .required(t("auth:password2required"))
      .matches(passwordRegex, t("auth:passwordMatches")),
  });

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    const newActiveStep = activeStep < 2 && activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleSubmit = async (values) => {
    dispatch(setEmail(values.workEmail));
    try {
      await postRecoveryEmail({ email: values.workEmail });
      dispatch(setStep(1));
      setActiveStep(1);
    } catch (error) {
      setMsgError(true);
      console.log(error);
    }
  };

  const handleSubmitPin = (values) => {
    dispatch(setPIN(values.pin));
    setTimeout(() => {
      dispatch(setStep(2));
      setActiveStep(2);
    }, 3000);
  };

  const handleSubmitPassword = async (values) => {
    try {
      const payload = { ...formPassword, password: values.password };
      await postRecovery(payload);
      setActiveStep(0);
      dispatch(setStep(0));
      dispatch(setEmail(""));
      dispatch(setPIN(""));
      navigate("/auth/login");
    } catch (error) {
      setError(true);
      console.log(error);
    }
  };

  const handleReTry = () => {
    dispatch(setEmail(""));
    dispatch(setPIN(""));
    dispatch(setStep(0));
  };

  return (
    <TemplateAuth spinner={spinner} transition={transition}>
      <Box sx={{ width: "100%", height: "100%" }}>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<ColorlibConnector />}
          sx={{ width: "100%" }}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === 0 && (
          <>
            <br />
            <p className={styles.text} style={{ textAlign: "center" }}>
              {t("auth:TextoExplicacion")}
            </p>
            <br />
            <Formik
              initialValues={{
                workEmail: "",
                workEmailConfirm: "",
              }}
              onSubmit={handleSubmit}
              validationSchema={validations}
              enableReinitialize={true}
            >
              {(formik) => (
                <Form id="d" autoComplete="off">
                  <div className={styles.boxEmail}>
                    <Grid container spacing={2}>
                      <Grid item={true} xs={12}>
                        {msgError && (
                          <p
                            className={styles.text}
                            style={{
                              fontSize: "1rem",
                              color: "red",
                              margin: "1rem 0px",
                            }}
                          >
                            {t("auth:TEXTOERROR")}
                          </p>
                        )}
                      </Grid>
                      <Grid item={true} xs={12}>
                        <UITextField
                          type="email"
                          formik={formik}
                          label={t("auth:email")}
                          name={"workEmail"}
                          disabled={formik.isSubmitting}
                        />
                      </Grid>
                      <Grid item={true} xs={12}>
                        <UITextField
                          type="email"
                          formik={formik}
                          label={t("auth:confirmEmail")}
                          name={"workEmailConfirm"}
                          disabled={formik.isSubmitting}
                        />
                      </Grid>
                      <Grid item={true} xs={12}>
                        <UIButton
                          type="submit"
                          style={{ height: "56px" }}
                          label={t("auth:continuar")}
                          loading={formik.isSubmitting}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </Form>
              )}
            </Formik>
          </>
        )}
        {activeStep === 1 && (
          <>
            <br />
            <p className={styles.text} style={{ textAlign: "center" }}>
              {t("auth:TextoExplicacionStep2")}
            </p>
            <br />
            <Formik
              initialValues={{
                pin: "",
              }}
              onSubmit={handleSubmitPin}
              enableReinitialize={true}
            >
              {(formik) => (
                <Form id="d" autoComplete="off">
                  <div className={styles.boxEmail}>
                    <Grid container spacing={2}>
                      <Grid item={true} xs={12}>
                        <UITextField
                          formik={formik}
                          label={t("auth:pin")}
                          name={"pin"}
                          disabled={formik.isSubmitting}
                        />
                      </Grid>
                      <Grid item={true} xs={12}>
                        <UIButton
                          type="submit"
                          style={{ height: "56px" }}
                          label={t("auth:continuar")}
                          loading={formik.isSubmitting}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </Form>
              )}
            </Formik>
          </>
        )}
        {activeStep === 2 && (
          <>
            <br />
            <p className={styles.text} style={{ textAlign: "center" }}>
              {t("auth:TextoExplicacionStep3")}
            </p>
            <br />
            <Formik
              initialValues={{
                password: "",
                password2: "",
              }}
              onSubmit={handleSubmitPassword}
              validationSchema={validationSchema}
              enableReinitialize={true}
            >
              {(formik) => (
                <Form id="d" autoComplete="off">
                  <div className={styles.boxEmail}>
                    <Grid container spacing={2}>
                      <Grid item={true} xs={12}>
                        {error && (
                          <p
                            className={styles.text}
                            style={{
                              fontSize: "1rem",
                              color: "red",
                              margin: "1rem 0px",
                            }}
                          >
                            {t("auth:TEXTOERROR")}
                          </p>
                        )}
                      </Grid>
                      <Grid item={true} xs={12}>
                        <UIPasswordField
                          formik={formik}
                          label={t("auth:password")}
                          name={"password"}
                          disabled={formik.isSubmitting}
                        />
                      </Grid>
                      <Grid item={true} xs={12}>
                        <UIPasswordField
                          formik={formik}
                          label={t("auth:password2")}
                          name={"password2"}
                          disabled={formik.isSubmitting}
                        />
                      </Grid>
                      <Grid item={true} xs={12}>
                        {error ? (
                          <UIButton
                            onClick={handleReTry}
                            style={{ height: "56px" }}
                            label={t("auth:reintentar")}
                            loading={formik.isSubmitting}
                          />
                        ) : (
                          <UIButton
                            type="submit"
                            style={{ height: "56px" }}
                            label={t("auth:continuar")}
                            loading={formik.isSubmitting}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </div>
                </Form>
              )}
            </Formik>
          </>
        )}
        <br />
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Button
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            {t("auth:Back")}
          </Button>
          <Button
            disabled={activeStep === 2}
            onClick={handleNext}
            sx={{ mr: 1 }}
          >
            {t("auth:Next")}
          </Button>
        </Box>
      </Box>
    </TemplateAuth>
  );
};
