import PropTypes from "prop-types";
import { Checkbox, Box, Typography } from "@mui/material";
import { Field } from "formik";

export const UICheckBox = ({
  label = "",
  name = "",
  checked,
  width = "100%",
  style,
  ...props
}) => {
  return (
    <Box
      sx={{
        width: width,
        display: "flex",
        flexDirection: "row",
      }}
      style={style}
    >
      <Field
        type="checkbox"
        name={name}
        as={Checkbox}
        checked={checked}
        {...props}
      />
      <Typography variant="subtitle2" sx={{ margin: "auto 0" }}>
        {label}
      </Typography>
    </Box>
  );
};

UICheckBox.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  width: PropTypes.string,
  style: PropTypes.object,
};
