import { configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import reducers from "./reducers";

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "authUser",
    "ui",
    "general",
    "parameterization",
    "profile",
    "tenantSettings",
    "timeOff",
    "polices",
    "workSchedules",
    "jobDescription",
    "recruitment",
    "requests",
    "socket",
    "public",
  ],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.env === "development" ? true : false,
});

export const RootState = store.getState();
export const AppDispatch = store.dispatch;

export default store;
