import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UITextField, UIPasswordField, UIButton, Toast } from "../components";
import { Form, Formik } from "formik";
import { Grid } from "@mui/material";
import { getTenant, TemplateAuth } from "./TemplateAuth";
import { useDispatch, useSelector } from "react-redux";
import {
  setKeycloakProfile,
  setMyKeycloakId,
  setRefreshToken,
  setAuthUser,
  setUser,
  setPermissions,
} from "@redux/slices/authUser";
import { setUserPreferences } from "@redux/slices/ui";
import { setTenantSettings } from "@redux/slices/tenant";
import { setMainColor, setMode } from "@redux/slices/parameterization";
import { getLocations, getLanguages, getSkills } from "api";
import { setLocations, setLanguages, setSkills } from "@redux/slices/general";
import { setInformation } from "@redux/slices/public";
import { authUserSelect } from "@redux/slices/authUser";

import i18n from "i18next";
import styles from "./index.module.css";
import "animate.css";
import { useNavigate } from "react-router-dom";

export const Login = () => {
  const dispatch = useDispatch();
  const { keycloakProfile } = useSelector(authUserSelect);

  const { t } = useTranslation(["auth"]);

  const [spinner, setSpinner] = useState(false);
  const [transition, setTransition] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (keycloakProfile) {
      return navigate("/home/indicators");
    }
  }, []);

  const handleSubmit = (values, { setSubmitting }) => {
    setError(false);
    fetch(`${process.env.REACT_APP_API_URL}/v1/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Realm: getTenant(),
        Client: "web-app-cli",
      },
      body: JSON.stringify(values),
    })
      .then((response) => {
        if (!response.ok) {
          setSubmitting(false);
          if (response?.status === 401) {
            setError(true);
          }
          throw new Error("Network response was not ok " + response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        const fetchData = async () => {
          delete data?.profile?.thumbnail;
          delete data?.companyLogo;

          try {
            await dispatch(setAuthUser(data?.access_token));
            await dispatch(setRefreshToken(data?.refresh_token));
            await dispatch(
              setKeycloakProfile({ is_superuser: data?.is_superuser })
            );
            await dispatch(setMyKeycloakId(data?.userId));
            await dispatch(setTenantSettings(data?.tenant_settings));
            await dispatch(setUser(data?.profile));
            await dispatch(setUserPreferences(data?.preferences));
            await dispatch(
              setMode(data?.preferences?.darkMode ? "dark" : "light")
            );
            await dispatch(
              setMainColor(data?.tenant_settings?.customColors?.mainColor)
            );
            await dispatch(
              setPermissions({
                permissions: data?.permissions,
                apps: data?.apps,
                paths: data?.paths,
              })
            );

            if (i18n && i18n.changeLanguage) {
              i18n.changeLanguage(data?.preferences?.language?.abb ?? "es");
            }
            const { data: locations } = await getLocations("selectors");
            await dispatch(setLocations(locations));
            const { data: language } = await getLanguages(true);
            await dispatch(setLanguages(language));
            const { data: skills } = await getSkills("selectors");
            await dispatch(setSkills(skills));

            setTransition(true);

            setTimeout(() => {
              setSpinner(true);
            }, 2000);

            setTimeout(() => {
              dispatch(setInformation({}));
              window.location.href = "/home/indicators";
            }, 4000);
          } catch (error) {
            setSubmitting(false);
            console.log("Auth error: ", error);
            Toast.fire({
              icon: "error",
              title: error,
            });
          }
        };

        fetchData();
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  return (
    <TemplateAuth spinner={spinner} transition={transition}>
      <Formik
        initialValues={{
          username: "",
          password: "",
        }}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {(formik) => (
          <Form id="d" autoComplete="off">
            <p
              className={styles.text}
              style={{
                fontSize: "3rem",
                color: "#212529",
                margin: "0 0 1em 0",
              }}
            >
              {t("auth:IniciarSesión")}
            </p>
            {error && (
              <p
                className={styles.text}
                style={{
                  fontSize: "1rem",
                  color: "red",
                  margin: "1rem 0px",
                }}
              >
                {t("auth:CredencialesIncorrectas")}
              </p>
            )}
            <Grid container spacing={4}>
              <Grid item={true} xs={12}>
                <UITextField
                  type="email"
                  formik={formik}
                  label={t("auth:email")}
                  name={"username"}
                  disabled={formik.isSubmitting}
                  autoComplete="email"
                />
              </Grid>
              <Grid item={true} xs={12}>
                <UIPasswordField
                  formik={formik}
                  label={t("auth:password")}
                  name={"password"}
                  disabled={formik.isSubmitting}
                  autoComplete="current-password"
                />
              </Grid>
              <Grid item={true} xs={12}>
                <UIButton
                  type="submit"
                  style={{ height: "56px" }}
                  label={t("auth:IniciarSesión")}
                  loading={formik.isSubmitting}
                />
              </Grid>
            </Grid>
            <br />
            <a
              href="/auth/recover-password"
              className={styles.text}
              style={{
                fontSize: "0.8rem",
                cursor: "pointer",
                color: "#212529",
              }}
            >
              {t("auth:olvidado")}
            </a>
          </Form>
        )}
      </Formik>
    </TemplateAuth>
  );
};
