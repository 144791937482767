import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { I18nextProvider } from "react-i18next";
import { store } from "./redux/store";
import i18n from "./i18n";
import App from "./App";
import "./App.css";

export default function Root(props) {
  let persistor = persistStore(store);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Suspense fallback={null}>
          <I18nextProvider i18n={i18n}>
            <App />
          </I18nextProvider>
        </Suspense>
      </PersistGate>
    </Provider>
  );
}
