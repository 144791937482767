import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { Login, Policy, Terms, RecoverPassword } from "./auth";
import { NotFound } from "./auth/404";

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path={"/auth/login"} element={<Login />} />
        <Route path={"/auth/policy"} element={<Policy />} />
        <Route path={"/auth/terms"} element={<Terms />} />
        <Route path={"/auth/404"} element={<NotFound />} />
        <Route path={"/auth/recover-password"} element={<RecoverPassword />} />
        <Route path="*" element={<Navigate to="/home/indicators" />} />
      </Routes>
    </Router>
  );
}
