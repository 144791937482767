import { httpClient } from "../setupAxios";

const ORGANIZATIONS = "/v1/org";
const GLOBAL = "/v1/glob";

export function getLocations(param, value = "") {
  if (param === "selectors") {
    return httpClient.get(`${ORGANIZATIONS}/location?${param}=true`);
  } else if (param === "id") {
    return httpClient.get(`${ORGANIZATIONS}/location?${param}=${value}`);
  } else {
    return httpClient.get(`${ORGANIZATIONS}/location`);
  }
}

export function getLanguages(selectors = false) {
  if (selectors) {
    return httpClient.get(`${GLOBAL}/language?selectors=true`);
  } else {
    return httpClient.get(`${GLOBAL}/language`);
  }
}

export function getSkills(param) {
  if (param === "selectors") {
    return httpClient.get(`${GLOBAL}/skill?selectors=true`);
  } else if (param?.id === "id") {
    return httpClient.get(`${GLOBAL}/skill?id=${param.value}`);
  } else {
    return httpClient.get(`${GLOBAL}/skill`);
  }
}
